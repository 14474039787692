<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days', 'last180Days', 'last365Days']"
                :max-selectable-days="365"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="compactMode"
              >
                Compact mode
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.temperatureChartOptions"
        :series="[temperatureSeries]"
      />
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, VBPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import DataDomainService from '@/service/datadomain.service'
import moment from '@/libs/moment'

export default {
  components: {
    DateRangePicker,
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    BFormCheckbox,
    BForm,
    BFormGroup,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compactMode: false,
      chartHeightNormalMode: 350,
      chartHeightCompactMode: 150,
      timeRange: {
        startDate: moment().subtract(365, 'days').toDate(),
        endDate: new Date(),
      },
      statistic: [],
      chartOptions: {
        temperatureChartOptions: {
          chart: {
            id: 'temperatureChart',
            type: 'line',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          stroke: {
            show: true,
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Temperature',
            align: 'left',
          },
          yaxis: [
            {
              labels: {
                formatter(val) {
                  return `${(val).toFixed(0)} °C`
                },
              },
              title: {
                text: 'Temperature',
              },
              minWidth: 0,
            },
          ],
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              // eslint-disable-next-line no-unused-vars
              formatter(val, config) {
                if (val) {
                  return `${(val).toFixed(0)} °C`
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L')
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartIds() {
      const chartIds = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.chartOptions).forEach(([key, chartOption]) => {
        chartIds.push(chartOption.chart.id)
      })

      return chartIds
    },
    chartHeight() {
      if (this.compactMode === true) {
        return this.chartHeightCompactMode
      }

      return this.chartHeightNormalMode
    },
    temperatureSeries() {
      return {
        name: 'Temperature',
        data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)), x.temperature]),
      }
    },
  },
  watch: {
    timeRange(val) {
      this.loadStatistic(val)
      this.updateCharts()
    },
    compactMode(newCompactMode) {
      for (let i = 0; i < this.chartIds.length; i += 1) {
        const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])

        // Update height
        opts.chart = this.$apexcharts.merge(opts, {
          height: this.chartHeight,
        })

        // Update Y axis tick amount to prevent overlapping labels
        opts.yaxis[0] = this.$apexcharts.merge(opts.yaxis[0], {
          tickAmount: newCompactMode === true ? 2 : 10,
        })

        this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
      }
    },
  },
  created() {
    this.loadStatistic()
  },
  mounted() {
    for (let i = 0; i < this.chartIds.length; i += 1) {
      const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])
      // Update height
      opts.chart = this.$apexcharts.merge(opts, {
        height: this.chartHeight,
      })

      this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
    }
  },
  methods: {
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
    loadStatistic() {
      const includeFields = [
        'Temperature',
      ]
      /*
        Performance data is stored in child list of statistic data. Performance data of e.g. yesterday may be contained in statistics of today.
        So we need to query statistic data one day before and after the range
        */
      DataDomainService.getStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.timeRange.endDate).add(1, 'days').endOf('day').toISOString(),
        include: includeFields,
        maxResultCount: 1000,
      }, { disableTenantFilter: true })
        .then(result => {
          this.statistic = result.items
        })
    },
    updateCharts() {
      for (let i = 0; i < this.chartIds.length; i += 1) {
        const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])

        // Update height
        opts.chart = this.$apexcharts.merge(opts, {
          height: this.chartHeight,
        })

        // Update X axis with new time range
        opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
          min: moment(this.timeRange.startDate).startOf('day').valueOf(),
          max: moment(this.timeRange.endDate).endOf('day').valueOf(),
        })

        this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
      }
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
