<template>
  <date-range-picker
    ref="picker"
    v-model="dateRange"
    opens="right"
    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
    :max-date="maxDate"
    :show-week-numbers="true"
    :show-dropdowns="true"
    :auto-apply="true"
    :linked-calendars="true"
    :ranges="rangesToSelect"
    control-container-class="form-control form-control-sm reportrange-text"
    @update="onUpdate"
  >
    <template v-slot:ranges="ranges">
      <div class="ranges">
        <ul>
          <li
            v-for="(range, id) in ranges.ranges"
            :key="id"
            @click="ranges.clickRange(range)"
          >
            <b>{{ range[2].displayName }}</b>
          </li>
        </ul>
      </div>
    </template>

    <template
      v-slot:input="picker"
      style="min-width: 350px;"
    >
      {{ getInputText(picker.startDate, picker.endDate) }}
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    DateRangePicker,
  },
  props: {
    selectableRanges: {
      type: Array,
      default: () => ['last3Days', 'last7Days', 'last14Days', 'last30Days'],
    },
    value: {
      type: Object,
      default: () => null,
    },
    maxSelectableDays: {
      type: Number,
      default: () => null,
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      dateRange: this.$props.value || {},
      dateRangeBefore: this.$props.value || {},
    }
  },
  computed: {
    maxDate() {
      const tzOffset = this.$moment.tz.zone(this.timezone).utcOffset(this.$moment())
      return this.$moment().add(tzOffset, 'minutes').toDate()
    },
    predefinedRanges() {
      const tzOffset = this.$moment.tz.zone(this.timezone).utcOffset(this.$moment())
      const to = this.$moment().add(tzOffset, 'minutes').toDate()
      return {
        today: [this.$moment().add(tzOffset, 'minutes').subtract(1, 'days').toDate(), to, { displayName: 'Today' }],
        last3Days: [this.$moment().add(tzOffset, 'minutes').subtract(3, 'days').toDate(), to, { displayName: 'Last 3 days' }],
        last7Days: [this.$moment().add(tzOffset, 'minutes').subtract(7, 'days').toDate(), to, { displayName: 'Last 7 days' }],
        last14Days: [this.$moment().add(tzOffset, 'minutes').subtract(14, 'days').toDate(), to, { displayName: 'Last 14 days' }],
        last30Days: [this.$moment().add(tzOffset, 'minutes').subtract(30, 'days').toDate(), to, { displayName: 'Last 30 days' }],
        last90Days: [this.$moment().add(tzOffset, 'minutes').subtract(90, 'days').toDate(), to, { displayName: 'Last 90 days' }],
        last180Days: [this.$moment().add(tzOffset, 'minutes').subtract(180, 'days').toDate(), to, { displayName: 'Last 180 days' }],
        last365Days: [this.$moment().add(tzOffset, 'minutes').subtract(365, 'days').toDate(), to, { displayName: 'Last 365 days' }],
        thisMonth: [this.$moment().add(tzOffset, 'minutes').startOf('month').toDate(), to, { displayName: 'This month' }],
        lastMonth: [
          this.$moment().add(tzOffset, 'minutes').subtract(1, 'months').startOf('month')
            .toDate(),
          this.$moment(to).subtract(1, 'months').endOf('month').toDate(),
          { displayName: 'Last month' },
        ],
        last3Months: [this.$moment().add(tzOffset, 'minutes').subtract(3, 'months').toDate(), to, { displayName: 'Last 3 months' }],
        last6Months: [this.$moment().add(tzOffset, 'minutes').subtract(6, 'months').toDate(), to, { displayName: 'Last 6 months' }],
        thisYear: [this.$moment().add(tzOffset, 'minutes').startOf('year').toDate(), to, { displayName: 'This year' }],
        last2Years: [this.$moment().add(tzOffset, 'minutes').subtract(2, 'year').toDate(), to, { displayName: 'Last 2 years' }],
        last3Years: [this.$moment().add(tzOffset, 'minutes').subtract(3, 'year').toDate(), to, { displayName: 'Last 3 years' }],
        last5Years: [this.$moment().add(tzOffset, 'minutes').subtract(5, 'year').toDate(), to, { displayName: 'Last 5 years' }],
      }
    },
    rangesToSelect() {
      const result = {}
      for (let i = 0; i < this.selectableRanges.length; i += 1) {
        if (typeof this.selectableRanges[i] === 'string') {
          const existingRange = this.predefinedRanges[this.selectableRanges[i]]
          if (existingRange) {
            result[this.selectableRanges[i]] = existingRange
          }
        }
      }

      return result
    },
    isValidDateRange() {
      if (this.maxSelectableDays === null) {
        return true
      }

      const start = this.$moment(this.dateRange.startDate)
      const end = this.$moment(this.dateRange.endDate)
      if (end.diff(start, 'days') > this.maxSelectableDays) {
        return false
      }

      return true
    },
  },
  beforeMount() {
    if (this.value.range && this.predefinedRanges[this.value.range]) {
      const [start, end] = this.predefinedRanges[this.value.range]

      this.value.startDate = start
      this.value.endDate = end

      // set data reactive. possible fix for components which uses dateRangePicker if only range by string is set. Need to be tested first!
      /*
      this.$set(this.value, 'startDate', start)
      this.$set(this.value, 'endDate', end)
      */
    }
  },
  methods: {
    getRange(from, to) {
      if (!from && !to) {
        return null
      }

      const self = this
      let foundRange = null
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.rangesToSelect).forEach(([key, value]) => {
        const range = value

        if (self.$moment(from).isSame(range[0], 'day') && self.$moment(to).isSame(range[1], 'day')) {
          foundRange = { key, item: range }
        }
      })

      return foundRange
    },
    getInputText(from, to) {
      if (!from && !to) {
        return 'No range selected'
      }

      const range = this.getRange(from, to)
      if (range) {
        return range.item[2].displayName
      }

      // If no exact range matches, display range by from - to
      return `${this.$options.filters.formatDate(from)} - ${this.$options.filters.formatDate(to)}`
    },
    onUpdate(event) {
      if (this.isValidDateRange) {
        const range = this.getRange(event.startDate, event.endDate)
        if (range) {
          // eslint-disable-next-line no-param-reassign
          event.range = range.key
        }

        this.$emit('input', event)
      } else {
        const firstRangeKey = Object.keys(this.rangesToSelect)[0]
        this.dateRange = {
          startDate: this.rangesToSelect[firstRangeKey][0],
          endDate: this.rangesToSelect[firstRangeKey][1],
        }

        this.$swal({
          title: 'Invalid time range.',
          text: `Please select max. ${this.maxSelectableDays} days`,
          icon: 'warning',
        })
      }
    },
  },
}

</script>
